import "@/styles/globals.scss";
import "../bet-nextv1/styles/global-tailwind.css";
import AppPageTwo from "@/bet-nextv1/AppPageTwo";
import { AnimatedMenu } from "@/components/AnimatedMenu";
import Head from "next/head";

function MyApp({ Component, pageProps }: any) {
	return (
		<>
			<Head>
				<title>고니 카지노</title>
			</Head>
			<AppPageTwo
				Component={Component}
				MenuComponent={AnimatedMenu}
				pageProps={pageProps}
			/>
		</>
	);
}

export default MyApp;
