import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import { useCore } from "@/core-nextv3/core/core";
import { getGameUrl } from "@/core-nextv3/ximax2/ximax2.api";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";
import { ModalVideo } from "../../ModalVideo";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

export const CardLive = ({ game, maintenance }: any) => {
	// console.log("game", game)
	const [gameUrl, setGameUrl] = useState<string>();

	const { user } = useContext(AuthContext);
	const { setAwaitLoadingHTTP } = useCore();
	const {
		setOpenMessageModal,
		setMessage,
		setMessageModalIcon,
		setOpenLoginModal,
	} = useSubCore();
	const { t } = useTranslation();

	let newWindow: any;

	async function getGameUrlPage(game: any) {
		const setting: any = {
			currency: "KRW",
			gameId: game.id,
			lang: "kr",
			...(game?.limit && { limit: game.limit.value }),
			document: { referencePath: user?.referencePath },
		};
		const result = await getGameUrl(setting);

		if (result?.status && result?.data?.returnCode === 0) {
			return result?.data?.gameUrl;
		}

		const messageModal = t("Cassino em manutenção");
		setMessage(messageModal);
		setMessageModalIcon("warning");
		setOpenMessageModal(true);
		return "/game404";
	}

	const openWindow = () => {
		newWindow = window.open("about:blank", "_blank");
	};

	async function openGame(game: string) {
		const allowCasino = (user as any)?.allowCasino;

		if (allowCasino !== false) {
			if (THEME_SETTING.openExternalsInNewTab) {
				const gameUrl = await getGameUrlPage(game);

				// window.open(gameUrl, "_blank");
				// return

				newWindow.location.href = gameUrl ?? "/game404";
				return;
			}

			setAwaitLoadingHTTP(true);

			setGameUrl(gameUrl ?? "/game404");
			setAwaitLoadingHTTP(false);
		} else {
			setMessage(
				t("Usuário não possui permissão para realizar apostas em casino"),
			);
			setOpenMessageModal(true);
		}
	}

	const handleClick = () => {
		if (maintenance) {
			return;
		}

		if (!user) {
			setOpenLoginModal(true);
		} else {
			openWindow();
			openGame(game);
		}
	};

	return (
		<>
			<div
				className={`${styles.cardLive} ${maintenance ? "" : styles.hoverEffect}`}
			>
				<div className={styles.cardLiveBox}>
					{/* <a href={game.url} target="_blank" rel="noreferrer"> */}
					{/* <button onClick={()=> openGame(game.id)} target="_blank" rel="noreferrer"> */}
					<button onClick={() => handleClick()} rel="noreferrer">
						<ImageSetBet src={game?.image._url} image={game?.image} />
					</button>
					{maintenance && (
						<div className={styles.overlay}>
							<span>점검중</span>
						</div>
					)}
				</div>

				{gameUrl && <ModalVideo setGameUrl={setGameUrl} gameUrl={gameUrl} />}
			</div>
		</>
	);
};
