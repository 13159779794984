import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default queryClient;

export const queryKeys = {
	coupons: "coupons",
	usedCoupons: "used-coupons",
	// rouletteHistory: "roulette-history",
};
