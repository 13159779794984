import { useSubCore } from "@/hooks/useSubCore";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../context/AuthContext";

export default function redirectTo(
	path: any,
	user: any,
	router: any,
	setOpenLoginModal: any,
) {
	if (user) {
		router?.push(path);
		return;
	}

	setOpenLoginModal(true);
	return;
}

export function useRedirectTo() {
	const { user } = useContext(AuthContext);
	const router = useRouter();
	const { setOpenLoginModal } = useSubCore();

	const _redirectTo = useCallback(
		(path: string) => {
			if (user) {
				router?.push(path);
				return;
			}

			setOpenLoginModal(true);
			return;
		},
		[user, router, setOpenLoginModal],
	);

	return {
		redirectTo: _redirectTo,
	};
}

export function useActiveRoute(
	path: string,
	activeSubPaths: Array<string> = [],
) {
	const { asPath } = useRouter();

	const subActive: boolean = useMemo(() => {
		return activeSubPaths.some((subHref) => {
			return asPath.includes(subHref);
		});
	}, [asPath, activeSubPaths]);

	const isActive = asPath.includes(path);

	return {
		isActive,
		subActive,
	};
}

type Props = {
	children: React.ReactNode;
	path: string;
	activeSubPaths?: Array<string>;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export function LinkActive({
	path,
	activeSubPaths = [],
	children,
	...rest
}: Props) {
	const { push } = useRouter();

	const { user } = useContext(AuthContext);
	const { setOpenLoginModal } = useSubCore();

	const handleClick = useCallback(
		(e: any) => {
			e.preventDefault();
			if (user) {
				push(path);
				return;
			}
			setOpenLoginModal(true);
			return;
		},
		[push, path, user, setOpenLoginModal],
	);
	const { isActive, subActive } = useActiveRoute(path, activeSubPaths);

	const className = rest.className || "";

	const _className =
		isActive || subActive ? `!text-primary ${className}` : className;

	// biome-ignore lint/performance/noDelete: <explanation>
	delete rest.className;
	return (
		<Link
			onClick={handleClick}
			href={path}
			// style={{
			// 	color: isActive || subActive ? "var(--yellow-color)" : "",
			// }}
			className={_className}
			{...rest}
		>
			{children}
		</Link>
	);
}
