//import '@/styles/globals.scss'
import "core-js";
import "@splidejs/react-splide/css";
import { Loading } from "@/bet-nextv1/Loading";
import dynamic from "next/dynamic";

import { AuthProvider } from "@/bet-nextv1/context/AuthContext";
import { FirebaseProvider } from "@/bet-nextv1/context/FirebaseContext";
import { useCore } from "@/core-nextv3/core/core";
import Types from "@/core-nextv3/type";
import { call } from "@/core-nextv3/util/call.api";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import { ACCOUNT_SETTING, THEME_SETTING } from "@/setting/setting";
import AltStyles from "@/styles/alt";
import GlobalClasses from "@/styles/globalClasses";
import { QueryClientProvider } from "@tanstack/react-query";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import queryClient from "../react-query/queryClient";

const NavigationSportsTwo = dynamic(() => import("../NavigationSportsTwo"), {
	loading: () => (
		<span
			style={{
				color: "white",
			}}
		>
			Loading
		</span>
	),
});

const FooterMobileTwo = dynamic(() => import("../FooterMobileTwo"), {
	loading: () => (
		<span
			style={{
				color: "white",
			}}
		>
			Loading
		</span>
	),
});

const HeaderThree = dynamic(() => import("../HeaderThree"), {
	loading: () => (
		<span
			style={{
				color: "white",
			}}
		>
			Loading
		</span>
	),
});

const HeaderTwo = dynamic(
	() => import("@/bet-nextv1/HeaderTwo").then((mod) => mod.HeaderTwo),
	{
		loading: () => (
			<span
				style={{
					color: "white",
				}}
			>
				Loading
			</span>
		),
	},
);

const MaintanceModal = dynamic(
	() => import("@/bet-nextv1/MaintanceModal").then((mod) => mod.MaintanceModal),
	{
		loading: () => (
			<span
				style={{
					color: "white",
				}}
			>
				Loading
			</span>
		),
	},
);

const MessageModal = dynamic(
	() => import("@/bet-nextv1/MessageModal").then((mod) => mod.MessageModal),
	{
		loading: () => (
			<span
				style={{
					color: "white",
				}}
			>
				Loading
			</span>
		),
	},
);

// BUG BROWSER ANTIGOS
if (!Array.prototype.at) {
	Object.defineProperty(Array.prototype, "at", {
		value: function (index: number) {
			index = Math.trunc(index) || 0;
			if (index < 0) index += this.length;
			if (index < 0 || index >= this.length) return undefined;
			return this[index];
		},
		writable: true,
		enumerable: false,
		configurable: true,
	});
}

const AppPageTwo = ({ Component, pageProps }: any) => {
	const { isDesktop } = useResponsive();
	const [maintance] = useState(false);
	const { setSelectedLanguage, selectedLanguage } = useCore();
	const { setSelectedTimezone } = useSubCore();
	const router = useRouter();

	const fetchTimezone = async () => {
		let timezone: any;

		if (selectedLanguage === "kr") {
			timezone = { id: "Asia/Seoul", label: "Asia/Seoul", value: "+09:00" };
		} else if (selectedLanguage === "pt") {
			timezone = {
				id: "America/Sao_Paulo",
				label: "America/Sao_Paulo",
				value: "-03:00",
			};
		} else {
			timezone = { id: "Asia/Seoul", label: "Asia/Seoul", value: "+09:00" };
		}

		await call(
			Types.SET_TIMEZONE_UTIL_SERVER,
			ACCOUNT_SETTING.merge({
				timezone: timezone,
			}),
		);

		setSelectedTimezone(timezone?.label);
	};

	useEffect(() => {
		fetchTimezone();
		setSelectedLanguage(pageProps.locale ?? "kr");
	}, []);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`;
		script.async = true;
		document.head.appendChild(script);
	}, []);

	if (router.pathname.includes("404")) {
		return <Component {...pageProps} />;
	}

	if (router.asPath.includes("debug")) {
		return <Component {...pageProps} />;
	}

	const getHeader = () => {
		if (THEME_SETTING.headerType === "alt") {
			return <HeaderTwo {...pageProps} />;
		}

		return <HeaderThree {...pageProps} />;
	};

	const getLayout = () => {
		if (router?.asPath === "/" && THEME_SETTING.redirectType === "loginPage") {
			return (
				<>
					<div className="indexPage">
						<Component {...pageProps} />
					</div>
				</>
			);
		}

		return (
			<>
				{!THEME_SETTING.headerInMiddle && getHeader()}
				<div className="layoutPages">
					<div className="layoutRight">
						<NavigationSportsTwo
							account={pageProps.account}
							sizeBanners={pageProps.sizeBanners}
						/>
					</div>
					<div className="middle">
						{THEME_SETTING.headerInMiddle && getHeader()}
						<Component {...pageProps} />
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{/* <GoogleReCaptchaProvider
                reCaptchaKey={"" + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    async    : false,
                    defer    : false,
                    appendTo : "head",
                    nonce    : undefined,
                }}
            > */}
			<FirebaseProvider>
				<AuthProvider {...pageProps}>
					<QueryClientProvider client={queryClient}>
						<GlobalClasses />
						<AltStyles />
						{/* {getHeader()} */}
						<div
							className="contentWrapper"
							style={{
								height: "100dvh",
								overflowY: "hidden",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{getLayout()}
							{!isDesktop && <FooterMobileTwo {...pageProps} />}
						</div>

						<Toaster
							position="top-center"
							reverseOrder={true}
							toastOptions={{
								duration: 3000,
							}}
							containerStyle={{
								zIndex: 999999,
							}}
						/>

						<Loading />

						<MessageModal account={pageProps.account} />

						{maintance && <MaintanceModal />}
					</QueryClientProvider>
				</AuthProvider>
			</FirebaseProvider>
			{/* </GoogleReCaptchaProvider> */}
		</>
	);
};

export default appWithTranslation(AppPageTwo);
