import { BannersLinks } from "@/bet-nextv1/BannersLinks";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext } from "react";
import { FaStar } from "react-icons/fa";
import styles from "./styles.module.scss";

export const NewsComponent: React.FC<any> = ({
	posts,
	events,
	sizeBanners,
}) => {
	// console.log("posts", posts)
	// const router = useRoutera()
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const router = useRouter();
	const { setOpenLoginModal } = useSubCore();

	return (
		<div className={styles.content}>
			<div className={styles.noticeHome}>
				<h2 className={styles.title}>{t("Avisos / Regras")}</h2>
				<div className={styles.listNotices}>
					{posts?.slice(0, 5).map((post: any, key: any) => (
						<div key={post.id} className={styles.listItem}>
							<FaStar />
							<div
								key={key}
								className={styles.notice}
								onClick={() =>
									redirectTo(
										`/deposit?tabIndex=7&clickedId=${post.id}`,
										user,
										router,
										setOpenLoginModal,
									)
								}
							>
								<p>{post?.title}</p>
							</div>
							<FaStar />
						</div>
					))}
				</div>
			</div>
			<div className={styles.noticeHome}>
				<h2>{t("Evento")}</h2>

				<div className={styles.listNotices}>
					{events?.slice(0, 5).map((event: any, key: any) => (
						<div key={event.id} className={styles.listItem}>
							<FaStar />
							<div
								key={key}
								className={styles.notice}
								onClick={() =>
									redirectTo(
										`/event?selectedId=${event.id}`,
										user,
										router,
										setOpenLoginModal,
									)
								}
							>
								<p>{event?.title}</p>
							</div>
							<div>
								<FaStar />
							</div>
						</div>
					))}
				</div>
			</div>
			{sizeBanners && (
				<div className={styles.banners}>
					{" "}
					<BannersLinks sizeBanners={sizeBanners} />
				</div>
			)}
		</div>
	);
};
