"use client";

import Image from "next/image";
import { IMAGE_PROXY_SETTINGS } from "./constants";
import { getProxiedImageUrl } from "./utils";

const { IS_PROXY_ENABLED } = IMAGE_PROXY_SETTINGS;

interface ImageVariants {
	name: string;
	id: string;
	type: string;
	_url: string;
	_150x150: string;
	_300x300: string;
	_480x480: string;
	_1024x1024: string;
	_1920x1920: string;
}

interface ImageSetProps {
	src?: string;
	image?: ImageVariants;
	width?: number;
	height?: number;
	aspectRatio?: number;
	className?: any;
	enabledGif?: any;
	domainFrom?: string;
	domainTo?: string;
	alt?: string;
	sizes?: string;
	onClick?: any;
	priority?: boolean;
	responsive?: boolean;
	quality?: number;
	style?: any;
	objectFit?: any;
	placeholder?: string;
}

function selectBestImageUrl(image: ImageVariants, width: number): string {
	if (width <= 150 && image._150x150) {
		return image._150x150;
	}
	if (width <= 300 && image._300x300) {
		return image._300x300;
	}
	if (width <= 480 && image._480x480) {
		return image._480x480;
	}
	if (width <= 1024 && image._1024x1024) {
		return image._1024x1024;
	}
	if (width <= 1920 && image._1920x1920) {
		return image._1920x1920;
	}
	return image._url;
}

const getStyle = (responsive: boolean, objectFit: any, style: any) => {
	if (responsive) {
		return {
			width: "100%",
			height: "auto",
			objectFit: objectFit || "cover",
			...style,
		};
	}
	return {
		...style,
	};
};

export const ImageSetBet = ({
	src,
	image,
	quality = 100,
	width = 1920,
	height = 400,
	enabledGif = false,
	alt = "",
	priority = false,
	responsive = true,
	className = {},
	onClick,
	style = {},
	objectFit,
	placeholder = "/assets/product_placeholder.png",
}: ImageSetProps) => {
	let finalSrc = src || "";

	if (!finalSrc) {
		if (
			enabledGif &&
			image?._url &&
			image._url.toLowerCase().endsWith(".gif")
		) {
			finalSrc = image._url;
		} else if (image) {
			// Ensure image is not undefined
			finalSrc = selectBestImageUrl(image, width);
		}
	}

	if (!finalSrc || finalSrc === "") {
		finalSrc = placeholder;
	}

	if (IS_PROXY_ENABLED) {
		// const proxiedSrc = getProxiedImageUrl(finalSrc);

		return (
			<Image
				className={className}
				onClick={onClick}
				loader={getProxiedImageUrl}
				src={finalSrc}
				width={width}
				height={height}
				alt={alt}
				priority={priority}
				loading={priority ? "eager" : "lazy"}
				style={getStyle(responsive, objectFit, style)}
				quality={quality}
			/>
		);
	}

	return (
		<Image
			className={className}
			onClick={onClick}
			src={finalSrc}
			width={width}
			height={height}
			alt={alt}
			priority={priority}
			loading={priority ? "eager" : "lazy"}
			style={getStyle(responsive, objectFit, style)}
			quality={quality}
		/>
	);
};
