import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { getGameUrl } from "@/core-nextv3/ximax2/ximax2.api";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

export const CardSlotProvider = ({ provider, maintenance }: any) => {
	const [gameUrl, setGameUrl] = useState();

	const { user } = useContext(AuthContext);
	const { setAwaitLoadingHTTP } = useCore();
	const {
		setOpenMessageModal,
		setMessage,
		setMessageModalIcon,
		setOpenLoginModal,
	} = useSubCore();
	const { t } = useTranslation();
	const router = useRouter();

	async function getGameUrlPage(gameId: string) {
		const setting: any = {
			currency: "KRW",
			gameId: gameId,
			lang: "kr",
			document: { referencePath: user?.referencePath },
		};
		const result = await getGameUrl(setting);

		if (result?.status && result?.data?.returnCode === 0) {
			return result?.data?.gameUrl;
		}

		const messageModal = t("Cassino em manutenção");
		setMessage(messageModal);
		setMessageModalIcon("warning");
		setOpenMessageModal(true);
		return "/game404";
	}

	async function openGame(gameId: string) {
		const allowCasino = (user as any)?.allowCasino;

		if (allowCasino !== false) {
			if (THEME_SETTING.openExternalsInNewTab) {
				const gameUrl = await getGameUrlPage(gameId);

				window.open(gameUrl ?? "/game404", "_blank");
				return;
			}

			setAwaitLoadingHTTP(true);

			setGameUrl(gameUrl);
			setAwaitLoadingHTTP(false);
		} else {
			setMessage(
				"Usuário não possui permissão para realizar apostas em casino",
			);
			setOpenMessageModal(true);
		}
	}

	return (
		<>
			<div
				className={`${styles.cardLive} ${maintenance ? "" : styles.hoverEffect}`}
			>
				<div className={styles.cardLiveBox}>
					<a
						onClick={() => {
							if (maintenance) {
								return;
							}

							redirectTo(
								`/slotsPage/${provider?.name}`,
								user,
								router,
								setOpenLoginModal,
							);
						}}
					>
						<ImageSetBet
							image={provider?.image}
							alt={provider?.image?.name}
							placeholder="/assets/img/slotdefault.png"
						/>
						{/* <div className={styles.cardLiveBoxLabel}>
                            {selectedLanguage === "pt" ? game?.name : game?.title_kr}
                        </div> */}
					</a>
					{maintenance && (
						<div className={styles.overlay}>
							<span>점검중</span>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
