/**
 * Configurações para o recurso de proxy de imagens.
 * Essas configurações são usadas para habilitar ou desabilitar o proxy de imagens
 * e para definir as URLs base para o proxy e o armazenamento.
 *
 * @property {boolean} isProxyEnabled - Indica se o proxy de imagens está habilitado.
 *   Este valor é obtido da variável de ambiente `NEXT_PUBLIC_IMAGE_PROXY_ENABLED`.
 * @property {string | undefined} proxyBaseUrl - A URL base do proxy de imagens.
 *   Este valor é obtido da variável de ambiente `NEXT_PUBLIC_IMAGE_PROXY_BASE_URL`.
 * @property {string | undefined} storageBaseUrl - A URL base do armazenamento de imagens.
 *   Este valor é obtido da variável de ambiente `NEXT_PUBLIC_STORAGE_BUCKET_BASE_URL`.
 */
export const IMAGE_PROXY_SETTINGS = {
	IS_PROXY_ENABLED: process.env.NEXT_PUBLIC_IMAGE_PROXY_ENABLED === "true",
	PROXY_BASE_URL: process.env.NEXT_PUBLIC_IMAGE_PROXY_BASE_URL,
	STORAGE_BASE_URL: process.env.NEXT_PUBLIC_STORAGE_BUCKET_BASE_URL,
};
