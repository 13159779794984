import { IMAGE_PROXY_SETTINGS } from "./constants";

const { IS_PROXY_ENABLED, PROXY_BASE_URL, STORAGE_BASE_URL } =
	IMAGE_PROXY_SETTINGS;

export const replaceDomain = (
	url: string,
	domainFrom: string,
	domainTo: string,
): string => {
	if (
		typeof url !== "string" ||
		typeof domainFrom !== "string" ||
		typeof domainTo !== "string"
	) {
		return url;
	}

	// Garantir que apenas o começo da URL é substituído
	const domainRegex = new RegExp(
		`^${domainFrom.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")}`,
	);
	return url.replace(domainRegex, domainTo);
};

interface CloudflareLoaderProps {
	src: string;
	width: number;
	quality?: number;
}

export const cloudflareLoader = ({
	src,
	width,
	quality = 100,
}: CloudflareLoaderProps): string => {
	// console.log("cloudflareLoader", src, width, quality);
	if (IS_PROXY_ENABLED && STORAGE_BASE_URL && PROXY_BASE_URL) {
		src = replaceDomain(src, STORAGE_BASE_URL, PROXY_BASE_URL);

		// const params = new URLSearchParams({
		// 	width: width.toString(),
		// 	quality: quality.toString(),
		// 	format: "auto",
		// }).toString();

		// Extrai apenas o caminho da imagem
		const parsedUrl = new URL(src, "https://dummy.com"); // URL temporária para extrair pathname
		const imagePath = parsedUrl.pathname;
		// console.log("imagePath", imagePath);

		return `${PROXY_BASE_URL}${imagePath}`;
	}

	// Caso `imageCDN` esteja desativado, retorna a URL original sem modificação
	return src;
};

export type ImageLoaderProps = {
	src: string;
	width: number;
	quality?: number;
};
export function getProxiedImageUrl({ src }: ImageLoaderProps): string {
	if (!STORAGE_BASE_URL || !src.startsWith(STORAGE_BASE_URL)) {
		// Retorna a URL original se não pertencer ao storage
		return src;
	}

	// Pega apenas o caminho da imagem (removendo a base do storage)
	const imagePath = src.replace(STORAGE_BASE_URL, "");

	// Se o proxy estiver ativado, substitui a URL base pelo proxy
	return IS_PROXY_ENABLED && PROXY_BASE_URL
		? `${PROXY_BASE_URL}${imagePath}`
		: src;
}
