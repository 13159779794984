import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import { logoutAuth } from "@/core-nextv3/auth/auth.api";
import { clearCart } from "@/core-nextv3/bet/bet.api";
import { useCore } from "@/core-nextv3/core/core";
import Types from "@/core-nextv3/type";
import { call } from "@/core-nextv3/util/call.api";
import { getAccid, getRecaptcha } from "@/core-nextv3/util/util";
import {
	collectAccountGameBalanceAll,
	getAccountBalanceAllWithTimeout,
} from "@/core-nextv3/ximax2/ximax2.api";
import { dateFormat } from "@/hooks/date.format";
import { useSubCore } from "@/hooks/useSubCore";
import { AUTH_SETTING, CART_BET_SETTING } from "@/setting/setting";
import debounce from "@mui/material/utils/debounce";
import { useIdle } from "@uidotdev/usehooks";
import { addMinutes } from "date-fns";
// import { getDocument } from "@/core-nextv3/document/document.api"
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import {
	Timestamp,
	type Unsubscribe,
	doc,
	onSnapshot,
} from "firebase/firestore";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import loginAuthBet from "../core/services/login/login-auth";
import { FirebaseContext } from "./FirebaseContext";
import type { AuthValuesType, LoginParams, UserDataType } from "./types";

// ** Defaults
const defaultProvider: AuthValuesType = {
	user: null,
	loading: true,
	isLogged: false,
	setUser: () => null,
	setLoading: () => Boolean,
	setIsLogged: () => Boolean,
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	CART_BACKEND: false,
	generalConfigs: undefined,
};

const AuthContext = createContext(defaultProvider);

type Props = {
	children: ReactNode;
	GENERAL_CONFIGS: any;
};

const AuthProvider = ({ children, GENERAL_CONFIGS: generalConfigs }: Props) => {
	const sessionLifetime = 1000 * 30;
	// const sessionLifetime                                        = 10 * 10
	const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
	const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
	const [CART_BACKEND] = useState<boolean>(defaultProvider.CART_BACKEND);
	const [isLogged, setIsLogged] = useState<boolean>(false);
	const loggedRef = useRef<boolean | null>(null);
	const firebaseApp = useContext(FirebaseContext);
	const { setAwaitLoadingHTTP, setBetCart, setIsLoadingUser } = useCore();
	const { setOpenLoginModal, setOpenMessageModal, setMessage, setReferral } =
		useSubCore();
	const router = useRouter();
	const idle = useIdle(sessionLifetime);
	// const [ generalConfigs, setGeneralConfigs ]                               = useState<any>(null)
	// const uniqueAuthRef = useRef<null>(null)
	const { t } = useTranslation();

	// const redirectPath = THEME_SETTING.redirectType === 'loginPage' ? '/home/' : '/'

	// console.warn(uniqueAuthRef.current)

	// useEffect(() =>
	// {
	//     if (user)
	//     {

	//         if (dateFormat(Timestamp.now().toDate()) > dateFormat(user?.activeDate))
	//         {
	//             sessionExpired()
	//         }
	//     }

	// }, []	)

	// useEffect(() =>
	// {
	//     (async () =>
	//     {
	//         const generalConfigs = await getDocument(GENERAL_CONFIGS_SETTING);
	//         setGeneralConfigs(generalConfigs)
	//     })()
	// }, [])

	useEffect(() => {
		if (router.query.ref) {
			setReferral(router.query.ref);
		}
	}, [router.query.ref]);

	useEffect(() => {
		let unsubscribe: Unsubscribe | undefined = undefined;
		(() => {
			const accid = getAccid();

			if (!accid) {
				return;
			}

			if (!firebaseApp?.firestore) {
				console.error("Reference path or Firestore is undefined.");
				return;
			}

			const userDoc = doc(
				firebaseApp.firestore,
				`/${accid}/session/documents/logoutAll`,
			);
			unsubscribe = onSnapshot(userDoc, (docRef) => {
				const data = docRef.data() as { logoutAll: boolean };

				if (data?.logoutAll) {
					handleLogout();
					router.push("/");
					return;
				}
			});
		})();

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, [firebaseApp?.firestore]);

	const shouldLogout = async () => {
		// (async () =>
		// {
		//     const generalConfigs = await getDocument(GENERAL_CONFIGS_SETTING);
		//     setGeneralConfigs(generalConfigs)
		// })()

		if (!generalConfigs?.timeToLogout || generalConfigs?.timeToLogout === 0) {
			return;
		}

		if (typeof user?.activeDate === "string") {
			const date = Timestamp.fromDate(new Date(user?.activeDate));

			if (
				dateFormat(Timestamp.now().toDate()) >
				dateFormat(addMinutes(date?.toDate(), generalConfigs?.timeToLogout))
			) {
				sessionExpired();
			}
		} else {
			if (
				dateFormat(Timestamp.now().toDate()) >
				dateFormat(
					addMinutes(user?.activeDate?.toDate(), generalConfigs?.timeToLogout),
				)
			) {
				sessionExpired();
			}
		}
	};

	const handleClose = async () => {
		if (user && loggedRef.current) {
			const setting: any = {
				currency: "KRW",
				document: {
					referencePath: user?.referencePath,
				},
			};

			await getAccountBalanceAllWithTimeout(setting);

			user.processingCash = false;

			setUser({
				...user,
				docRef: user?.referencePath,
				referencePath: user?.referencePath,
			} as UserDataType);
		}
	};

	const handleXimax = debounce(() => {
		if (document.visibilityState === "visible") {
			handleClose();
		}
	}, 2000);

	useEffect(() => {
		// const handleVisibilityChange = debounce(() =>
		// {
		//     if (document.visibilityState === "visible")
		//     {
		//         handleClose()
		//     }
		// }, 2000);
		const handleVisibilityChange = () => {
			if (user && !user?.processingCash) {
				user.processingCash = true;
				setUser({
					...user,
					docRef: user?.referencePath,
					referencePath: user?.referencePath,
				} as UserDataType);
			}

			handleXimax();

			if (user && generalConfigs?.timeToLogout) {
				shouldLogout();
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		window.addEventListener("focus", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("focus", handleVisibilityChange);
		};
	}, [user]);

	const onLogin = (data: any, firstLogin = false) => {
		setAwaitLoadingHTTP(true);
		setIsLoadingUser(true);
		setLoading(true);

		if (!loggedRef.current) {
			setUser({
				...data,
				docRef: data?.referencePath,
				referencePath: data?.referencePath,
			} as UserDataType);
		}

		const unsubscribe: Unsubscribe = onSnapshot(
			doc(firebaseApp.firestore, data?.referencePath),
			async (docRef) => {
				// setAwaitLoadingHTTP(false)
				const _data: any = docRef.data();

				if (_data) {
					if (!_data.status) {
						const message = t("user is not active.");
						return handleLogoutWithMessage(message);
					}

					if (_data.blocked) {
						const message = t("user is blocked.");
						return handleLogoutWithMessage(message);
					}

					if (_data.removed) {
						const message = t("user removed.");
						return handleLogoutWithMessage(message);
					}

					if (firstLogin) {
						const auth = getAuth();
						try {
							await signInWithCustomToken(auth, _data?.authToken);
						} catch {
							return handleLogout();
						}
					}
				} else {
					const message = t("Entrar em contato com administrador");
					displayMessage(message);
					handleLogout();
					return;
				}

				if (loggedRef.current) {
					setAwaitLoadingHTTP(true);
					setIsLoadingUser(true);
					const uniqueAuth = localStorage.getItem("uniqueAuth");

					if (uniqueAuth && _data?.uniqueAuth) {
						// compara a seção do localstorage c/ realtime do BD-

						if (String(uniqueAuth) !== String(_data?.uniqueAuth)) {
							loggedRef.current = null;
							localStorage.removeItem("uniqueAuth");
							await collectAccountGameBalanceAll({
								currency: "KRW",
								document: {
									referencePath: docRef?.ref?.path,
								},
							});
							unsubscribe();
							return handleLogoutWithMessage(
								`${_data?.clientIP} 에서 중복로그인되어 로그아웃됩니다.`,
							);
						}

						// backup
						// if (uniqueAuthRef.current === null) {
						//   uniqueAuthRef.current = _data?.uniqueAuth
						// }
						// else if (
						//   _data?.uniqueAuth &&
						//   uniqueAuthRef.current != _data?.
						// ) {
						//   loggedRef.current = null
						//   return handleLogoutWithMessage(
						//     `${_data?.clientIP} 에서 중복로그인되어 로그아웃됩니다.`
						//   )
						// }

						// if (user?.unreadMessages.length !== countersMessages.unreadMessages)
						// {
						//     setCountersMessages({
						//         unreadMessages : user?.unreadMessages.length,
						//         readMessages   : countersMessages.readMessages
						//     })
						// }

						setUser({
							..._data,
							docRef: docRef?.ref,
							referencePath: docRef?.ref?.path,
						} as UserDataType);

						setIsLoadingUser(false);
						setAwaitLoadingHTTP(false);
					}

					// se o token da seção local (localstorage) for nulo, desloga a seçao no back
					else if (!uniqueAuth) {
						await handleLogout(unsubscribe);
					}
				}
			},
		);

		return unsubscribe;
		// setIsLoadingUser(false)
		// setAwaitLoadingHTTP(false)
	};

	useEffect(() => {
		if (user && idle) {
			shouldLogout();
		}
	}, [idle, generalConfigs?.timeToLogout]);

	// useEffect(() =>
	// {

	//     if (user)
	//     {

	//         if (dateFormat(Timestamp.now().toDate()) > dateFormat(user?.activeDate))
	//         {
	//             sessionExpired()
	//         }
	//     }
	// }, [ idle ])

	// useEffect(() =>
	// {
	//     if (user?.id === "4Mz1m6HrS7WaQkqQzk34")
	//     {
	//         setCART_BACKEND(false)
	//     }
	//     else
	//     {
	//         setCART_BACKEND(true)
	//     }
	// }, [ isLogged ])

	useEffect(() => {
		// setAwaitLoadingHTTP(true)
		setIsLoadingUser(true);
		setLoading(true);

		let unsubscribe: Unsubscribe | null = null;

		call(Types.GET_LOGGED_AUTH_SERVER, AUTH_SETTING).then((result): void => {
			// console.warn("Acionou o getLogged", result, idle)

			// const keepAlive2 = localStorage.getItem("keep-alive")
			// console.warn("keepAlive", keepAlive2)

			// const countdown = sessionLifetime - (new Date().getTime() - new Date(keepAlive2 ?? new Date()).getTime())
			// console.warn("countDown", countdown)

			// if (result?.status && countdown < 0)
			// {
			//     sessionExpired()
			// }
			// else

			if (result?.status) {
				loggedRef.current = true;
				setIsLogged(true);
				unsubscribe = onLogin(result?.data);
				setLoading(false);
				// setAwaitLoadingHTTP(false)
			} else if (!result?.status) {
				// setUser(null)
				setIsLogged(false);
				setLoading(false);
				// loggedRef.current = null
				// localStorage.removeItem("uniqueAuth")
				setIsLoadingUser(false);
				// setAwaitLoadingHTTP(false)
			}

			// {

			//     // if (router.pathname !== "/")
			//     // {
			//     //     const currentQuery = router.query;

			//     //     router.replace({
			//     //         pathname : "/",
			//     //         query    : currentQuery,
			//     //     })
			//     // }

			// }
		});

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	// useEffect(() =>
	// {

	//     if (idle)
	//     {
	//         router.reload()
	//     }

	// }, [ idle ])

	const handleLogin = async (params: LoginParams) => {
		setAwaitLoadingHTTP(true);
		setIsLoadingUser(true);

		if (localStorage.getItem("uniqueAuth")) {
			loggedRef.current = null;
			localStorage.removeItem("uniqueAuth");
		}

		// const incognito = await detectIncognito()

		// // if (incognito.isPrivate) {
		//                   setAwaitLoadingHTTP(false)

		//   return {
		//     logged: false,
		//     message: 'Sign in using incognito mode is not allowed.',
		//   }
		// }

		const token = await getRecaptcha("login");

		const result: any = await loginAuthBet(
			AUTH_SETTING.merge({
				login: params.login,
				password: params.password,
				token: token,
			}),
		);

		// setAwaitLoadingHTTP(false)

		if (result?.status) {
			const data = result?.data;

			if (!data?.status) {
				console.warn("notApproved");
				setUser(null);
				setAwaitLoadingHTTP(false);
				setIsLoadingUser(false);
				return { logged: false, registerNotApproved: true };
			}

			if (data?.blocked) {
				console.warn("blocked");
				setUser(null);
				setAwaitLoadingHTTP(false);
				setIsLoadingUser(false);
				return { logged: false, blocked: true };
			}

			if (data?.removed) {
				console.warn("removed");
				setUser(null);
				setAwaitLoadingHTTP(false);
				setIsLoadingUser(false);
				return { logged: false, removed: true };
			}

			// await createAccount({
			//     currency : "KRW",
			//     document : {
			//         referencePath : result?.data?.referencePath
			//     }
			// })

			if (!localStorage.getItem("uniqueAuth")) {
				localStorage.setItem("uniqueAuth", result?.data?.uniqueAuth);
			} else {
				localStorage.setItem("uniqueAuth", localStorage?.getItem("uniqueAuth"));
			}

			setOpenLoginModal(false);
			setIsLogged(true);
			loggedRef.current = true;
			await collectAccountGameBalanceAll({
				currency: "KRW",
				document: {
					referencePath: result?.data?.referencePath,
				},
			});
			onLogin(result?.data, true);
			router.push("/");
			// localStorage.setItem("keep-alive", new Date().toISOString())
			setIsLoadingUser(false);
			setAwaitLoadingHTTP(false);

			// salva a seção unica no localstorage

			// localStorage.setItem("uniqueAuth", result?.data?.uniqueAuth)

			return { logged: true, message: null };
		}

		// usuario bloqueado manda um error logo cairá aqui (gambiarra)
		const error = result?.error;
		const blocked = error === "Usuário bloqueado!";
		setIsLoadingUser(false);
		setAwaitLoadingHTTP(false);
		return {
			logged: false,
			message: result?.message || result?.error,
			count: result.count,
			blocked: blocked,
		};
	};

	const handleLogout = async (callback = () => {}) => {
		setIsLoadingUser(true);
		setAwaitLoadingHTTP(true);
		loggedRef.current = null;

		if (callback) {
			callback();
		}

		localStorage.removeItem("keep-alive");
		localStorage.removeItem("uniqueAuth");
		setUser(null);
		await clearCart(CART_BET_SETTING);
		await logoutAuth(AUTH_SETTING);
		await signOut(getAuth());
		setBetCart(null);
		setIsLogged(false);
		// loggedRef.current = false
		setLoading(false);

		// limpa o cache do login unico
		// uniqueAuthRef.current = null

		// router.reload()

		//setOpenLoginModal(router.pathname !== '/')
		setIsLoadingUser(false);
		router.replace("/");

		setAwaitLoadingHTTP(false);
	};

	const handleLogoutWithMessage = (message: string) => {
		loggedRef.current = null;
		displayMessage(message);
		handleLogout();
	};

	const displayMessage = (message: string) => {
		setMessage(message);
		setOpenMessageModal(true);
	};

	const sessionExpired = () => {
		setLoading(false);
		return handleLogoutWithMessage("활동이 없어 자동 로그아웃합니다.");
	};

	const values = {
		user,
		loading,
		setUser,
		setLoading,
		setIsLogged,
		login: handleLogin,
		logout: handleLogout,
		// keepAlive,
		CART_BACKEND,
		isLogged,
		generalConfigs,
	};

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);
export { AuthContext, AuthProvider, useAuth };
