import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { ImageSetBet } from "../ImageSetBet";
import styles from "./styles.module.scss";

export const BannersLinks: React.FC<any> = ({ sizeBanners }: any) => {
	const { isDesktop } = useResponsive();

	const handleBannerClick = (link: any) => {
		if (link) {
			const cleanlink = link.replace(/^https?:\/\//, "");
			window.open(`https://${cleanlink}`);
		}
	};

	return (
		<div className={styles.imagesLink}>
			{sizeBanners?.bannersLinks?.map(
				(banner: any) =>
					(isDesktop || banner?.mobile) && (
						<div key={banner?.id}>
							<ImageSetBet
								alt=""
								// className={styles.imageLink}
								className="cursor-pointer"
								objectFit="contain"
								src={
									isDesktop ? banner?.imageBanner?._url : banner?.mobile?._url
								}
								onClick={() => handleBannerClick(banner?.url)}
							/>
						</div>
					),
			)}
		</div>
	);
};
