import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { LinkActive } from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { RiCoupon3Fill } from "react-icons/ri";
import styles from "./styles.module.scss";

export const AnimatedMenu = ({ account }: any) => {
	const { setOpenLoginModal } = useSubCore();
	const router = useRouter();
	const [_toggle, _setToggle] = useState(false);
	const { t } = useTranslation();

	const { user } = useContext(AuthContext);

	const handleLogoLink = async (path: string) => {
		if (router.asPath === "/") {
			router.reload();
		} else {
			await router.push(path);
		}
	};

	return (
		<>
			{THEME_SETTING.headerInMiddle && (
				<div className={styles.menuLeft}>
					<div className={styles.logoMenu}>
						<a onClick={() => handleLogoLink("/")} className="logo">
							<img src={account?.logo?._url} alt="logo" />
						</a>
					</div>
				</div>
			)}
			<div className={styles.boxNavigation}>
				<div className={styles.itemContainer}>
					<LinkActive path="/liveCasino">
						<img src="/assets/icon/Live-Casino.png" alt="live casino" />{" "}
						{t("live casino")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/slotsCasino" activeSubPaths={["/slotsPage"]}>
						<img src="/assets/icon/Slot.png" alt="Slot" /> 슬롯게임
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=0">
						<img src="/assets/icon/Deposit.png" alt="Deposit" /> {t("Depósito")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=1">
						<img src="/assets/icon/Withdraw.png" alt="Withdraw" /> {t("Saque")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=2">
						<img src="/assets/icon/Money-Exchange.png" alt="" />{" "}
						{t("Transferencias")}
					</LinkActive>
				</div>

				<div className={styles.itemContainer}>
					<LinkActive path="/coupon" className="!ml-2">
						<RiCoupon3Fill size={18} />
						<span>{t("Caixa de cupom")}</span>
					</LinkActive>
				</div>
			</div>

			<div className={styles.boxNavigation}>
				<div className={styles.itemContainer}>
					<LinkActive path="/event">
						<img src="/assets/icon/Event.png" alt="" />
						{t("Evento")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=7">
						<img src="/assets/icon/News.png" alt="" />
						{t("Rules")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=3">
						<img src="/assets/icon/Message.png" alt="" />
						{t("Customer center")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=8">
						<img src="/assets/icon/Loosing-Bonus.png" alt="" />
						루징신청
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/deposit/?tabIndex=4">
						<img src="/assets/icon/Chat.png" alt="" />
						쪽지함
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<LinkActive path="/perfil">
						<img src="/assets/icon/My-Page.png" alt="My-Page" />
						{t("My page")}
					</LinkActive>
				</div>
				<div className={styles.itemContainer}>
					<p onClick={() => window.location.reload()}>
						<img src="/assets/icon/4208523.png" alt="" />
						<span>쿠키삭제</span>
					</p>
				</div>
			</div>
		</>
	);
};
