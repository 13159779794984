import { APIS_MAP } from "@/setting/setting";

declare const window: any;
declare const fetch:any;

const call = async (url: string, body?: any) => 
{    
    //let response : any;
    let result;

    if (typeof window !== "undefined" && APIS_MAP)
    {
        const currentHostname = window.location.hostname;
        const apiUrl          = APIS_MAP[currentHostname];

        if (apiUrl)
        {
            url = url.replace(process.env.NEXT_PUBLIC_API_SOURCE_URL || "", apiUrl);
        }
    }

    // if (body.server)
    // {
    //    url = url.replace(process.env.NEXT_PUBLIC_API_SOURCE_URL || "", process.env.NEXT_PUBLIC_API_BUILD_URL || "");
    // }

    const headers: any = {
        "Accept"                           : "application/json",
        "Content-Type"                     : "application/json",
        "Access-Control-Allow-Credentials" : "true",
    };

    const response = await fetch(url, {
        method      : "POST",
        body        : JSON.stringify(body),
        headers     : headers,
        credentials : "include",
        //cache       : 'no-store',
        cache       : body.server ? "force-cache" : "default",
        next        : { revalidate : body?.revalidate === undefined ? 60 * 5 : body?.revalidate }
    });

    if (response.status >= 400)
    {
        console.error("http-error", url, body);
    }

    result = await response.json();
            
    try
    {
        //
    }
    catch (e:any)
    {
        e;

        result = {
            status      : false,
            error       : "Verifique se os dados foram digitados ou selecionados corretamente!",
            serverError : true,
        }; 
    }

    return result;
};

const callFormData = async (url: string, body?: any) => 
{
    const headers: any = {
        //"Accept" : "application/json",
        //"Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials" : "true",
    };

    // const form = new FormData()
    // const keys = Object.keys(body)
    // for(let i =0; i<keys.length; i++){
    //   console.log(keys[i])
    //     console.log(body[keys[i]])
    //   form.append(keys[i], body[keys[i]])
    // }
    // console.log(form)
    // console.log(body)

    const response = await fetch(url, {
        method      : "POST",
        body        : body,
        headers     : headers,
        credentials : "include"
    });          

    const data = await response.json();      

    return data;
};

const calls = async (...calls: any) => 
{
    const promises = [];

    for (const call of calls) 
    {
        promises.push(call);
    }

    return await Promise.all(promises);
};

const revalidateResults = (results: any) => 
{
    let error       = false;
    let serverError = false;

    for (const result of results) 
    {
        if (!result.status)
        {
            error = true;
        }

        if (result.serverError)
        {
            serverError = true;
        }
    }

    if (error) 
    {
        return {
            notFound   : !serverError,
            revalidate : true,
        };
    }

    return {
        revalidate : false,
    };
};

const parseResponse = async (maps: any, result: any) => 
{
    if (result.data && maps.model) 
    {
        result.data = new maps.model(result.data);
    } 
    else if (result.collection && maps.collection) 
    {
        result.collection = new maps.collection(result.collection);
    }

    return result;
};

export { 
    call, 
    callFormData, 
    calls, 
    parseResponse, 
    revalidateResults,
};
