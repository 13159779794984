import { AnimatedMenu } from "@/components/AnimatedMenu";
//import { getAccid } from "@/core-nextv3/util/util"
import { LinksTabs } from "@/components/LinksTabs";
import { NewsComponent } from "@/components/newsComponent";
import { setting } from "../core-nextv3/util/setting";

const getAccid = () => {
	return process.env.NEXT_PUBLIC_ACCID;
};

// need change
const platform = {
	id: "hlvsk9bS",
	label: "E-commerce",
	value: "ecommerce",
};

const REVALIDATE_SETTING = 60 * 30;

// ACCOUNT
const ACCOUNT_SETTING: any = setting({
	maccid: "default",
	accid: getAccid(),
	appid: "accounts",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/accounts/grids/5vTO1pyeQ1DrUjtmzOTx",
	},
});

// ADMIN
const ADMIN_SETTING: any = setting({
	accid: "default",
	maccid: "default",
	appid: "admin",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/admin/grids/LNBBseRY9WAQVP6enw8Y",
	},
});

// OPTIONS
const OPTIONS_SETTING: any = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},
});

// CASHIER OPTIONS_SETTING
const CASHIER_OPTIONS_SETTING: any = setting({
	id: "utJ6QC0WCYi3vz8j1y4y",
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	//noCache: true,
	map: true,
	mapItems: {
		referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},
});

// CATEGORY
const CATEGORY_SETTING: any = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	perPage: 100,
	map: true,
	mapItems: {
		referencePath: "default/category/grids/6diDjwiaSnSRWAsxwiL8",
	},
});

// BRAND
const BRAND_SETTING: any = setting({
	accid: "default",
	appid: "brand",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
		referencePath: "default/brand/grids/ot3lqYSvN217NiyWpivx",
	},
});

//STORE
const STORE_SETTING: any = setting({
	accid: "default",
	appid: "store",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "name",
	mapItems: {
		referencePath: "default/store/grids/dIuBzrJDFgCZKt5GWTMT",
	},
});

// OPTION
const OPTION_SETTING: any = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},
});

const PAYMENT_METHOD_OPTION_SETTING: any = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/option/documents/905mYADagPcaK6L8kJ2h",
	},
	map: true,
	mapItems: {
		referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},
});

const ORDER_ACTIONS_OPTION_SETTING: any = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/option/documents/BGXNgCM6A1iMoMnSUOJm",
	},
	map: true,
	mapItems: {
		referencePath: "default/option/grids/l3nQUnHfHzoKTEkNfRh8",
	},
});

// FAVORITE
const FAVORITE_SETTING: any = setting({
	accid: "default",
	appid: "favorite",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/favorite/grids/1CxBJQBxtmqCOI268kBM",
	},
	form: {
		referencePath: "default/favorite/forms/LyIbtpzvgRx6BYOpZt0v",
	},
});

//forms
const FORM_AUTH_SETTING: any = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/users/forms/blPx14NqY0QA857bhtJh",
	},
	map: true,
	mapItems: {
		referencePath: "default/users/forms/blPx14NqY0QA857bhtJh",
	},
});

const FORM_PRODUCT_SETTING: any = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/product/forms/u8iWyWFyPMjoNeDZwuC0",
	},
	map: true,
	mapItems: {
		referencePath: "default/product/forms/u8iWyWFyPMjoNeDZwuC0",
	},
});

const FORM_ORDER_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/orderPDV/forms/GifjFlCKtHPhjmykE2q7",
	},
	map: true,
	mapItems: {
		referencePath: "default/orderPDV/forms/GifjFlCKtHPhjmykE2q7",
	},
});

//ORDER
const ORDER_SETTING: any = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	//noCache: true,
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
	plusBonus: {
		referencePath: `/${getAccid()}/depositBonus/documents/2AjMdcd4qyy5EPNErMPh`,
	},
	scheduledBonus: {
		referencePath: `/${getAccid()}/depositBonus/documents/faJsT8AnAorMph20kWfu`,
	},
	form: {
		referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	gameSettings: {
		referencePath: `${getAccid()}/levelTables/documents/zEE6h1WsHonx2SmBHhIj`,
	},
});

//MINIGAME
const MINIGAME_SETTING = setting({
	accid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/financial/grids/NYreIgL4MGZvqCOpei8r",
	},
	form: {
		referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
});

const PROVIDER_SETTING = setting({
	accid: "default",
	appid: "minigame",
	colid: "providers",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/minigame/grids/cYZDpC6XQLAZwL9bfPkg",
	},
	form: {
		referencePath: "default/minigame/forms/udM7Je0wKJbPYp3NIvBa",
	},
});

//ORDER
const CASHIER_SETTING: any = setting({
	accid: "default",
	appid: "cashier",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/cashier/grids/9SQlOfbi61kO6ERUzv51",
	},
	form: {
		referencePath: "default/cashier/forms/vBpVqR4sMz49EYqRXJUa",
	},
});

const GRID_ORDER_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: platform,
	document: {
		// default/orderPDV/grids/QnO0PhgvdTCsITeVytQC
		referencePath: "default/orderPDV/grids/2JTDkh1pQSphGtkvh4Dx",
	},
	map: true,
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

//FAQ
const FAQ_SETTING: any = setting({
	accid: "default",
	appid: "faq",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "order",
	asc: true,
	mapItems: {
		referencePath: "default/faq/grids/wGDWuxxR1SCCcRPc9BgA",
	},
});

//PRODUCT
const PRODUCT_SETTING: any = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	platform: platform,
	perPage: 100,
	orderBy: "postdate",
	asc: false,
	map: true,
	mapItems: {
		referencePath: "default/product/grids/ZUnonLswnK67KE4lnDmg",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	form: {
		referencePath: "default/product/forms/u8iWyWFyPMjoNeDZwuC0",
	},
});

// COLLECTION GRID PRODUCT
const COLLECTION_GRID_PRODUCT_SETTING: any = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	platform: platform,
	perPage: 100,
	orderBy: "postdate",
	asc: false,
	map: true,
	mapItems: {
		referencePath: "default/product/grids/wffcDe2oARqDYsstirTQ",
	},
	priceName: {
		referencePath: "default/priceName/documents/IHB4sw0sMlKnyipukzBl",
	},
	stockName: {
		referencePath: "default/stockName/documents/H7ny9BhRSF6jj1KsfeDW",
	},
	form: {
		referencePath: "default/product/forms/u8iWyWFyPMjoNeDZwuC0",
	},
});

//GRID PRODUCT
const GRID_PRODUCT_SETTING: any = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: "default/product/grids/wffcDe2oARqDYsstirTQ",
	},
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

//PRODUCT VARIANT
const VARIANT_SETTING: any = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/8fK0IK1Lfo5Jg0gEIG2T",
	},
});

//PAGE
const PAGE_SETTING: any = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/iBqwfjj1mYhVKHRnUuTn",
	},
});

// HOME PAGE
const HOME_PAGE_SETTING: any = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/yRP915cQD7mknZnQfaTR",
	},
	document: {
		referencePath: `${getAccid()}/page/documents/x7QfWZRN8FUuJM4OtlaN`,
	},
});

// PAGE
const PAGE_TEXT_SETTING: any = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
});

//COMMET
const COMMET_SETTING: any = setting({
	accid: "default",
	appid: "comment",
	colid: "documents",
	platform: platform,
	orderBy: "order",
	map: true,
	mapItems: {
		referencePath: "default/comment/grids/JUF8UibMeGt3GbFTtCbA",
	},
	form: {
		referencePath: "default/comment/forms/RUHBFkTuTsKGOiVBbbVr",
	},
});

//COMMET
const BLOG_SETTING: any = setting({
	accid: "default",
	appid: "blog",
	colid: "documents",
	platform: platform,
	orderBy: "date",
	map: true,
	mapItems: {
		referencePath: "default/blog/grids/gQm4SWthYUYtVdDqEfiA",
	},
});

//CART
const CART_BET_SETTING: any = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "cart",
	colid: "documents",
	flatFixtureAppid: "fixture",
	flatFixtureColid: "flatFixture",
	platform: platform,
	map: true,
	form: {
		referencePath: "/default/cart/forms/yi8sxDEbExlshNIXREV3",
	},
	mapItems: {
		referencePath: "default/cart/grids/nVDufZ3q6CQvhqW53uz0",
	},
	gameSettings: {
		referencePath: `${getAccid()}/levelTables/documents/zEE6h1WsHonx2SmBHhIj`,
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
});

//CONTAINER
const CONTAINER_SETTING: any = setting({
	accid: "default",
	appid: "container",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "name",
	mapItems: {
		referencePath: "default/container/grids/pAKM6GDtlwrLhTxDH9mV",
	},
});

// VIDEO
const VIDEO_SETTING: any = setting({
	accid: "default",
	appid: "video",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/video/grids/IjIAOUOtndWDSUxjAMw8",
	},
});

// AUTH
const AUTH_SETTING: any = setting({
	maccid: "default",
	accid: getAccid(),
	// accid: 'default',
	appid: "users",
	colid: "documents",
	platform: platform,
	loginPath: "code",
	form: {
		referencePath: "default/users/forms/5BMnB6DwQDHZ0ir8zatM",
	},
	map: true,
	mapItems: {
		referencePath: "default/users/grids/0eEDzFDS30Doqu3rc9DB",
	},
});

// SELLER
const SELLER_SETTING: any = setting({
	accid: "default",
	appid: "seller",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/seller/forms/dFKw5X184Rx91OJDNCvN",
	},
	map: true,
	mapItems: {
		referencePath: "default/seller/grids/TLtCSxUTHtwehbuKgo0W",
	},
	grid: {
		referencePath: "default/seller/grids/syMRTEFBAgSlJ7PE2nsD",
	},
});

const COLLECTION_GRID_AUTH_SETTING: any = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/users/grids/c8i9eKoWMBWZgx9o9rIh",
	},
});

const GRID_AUTH_SETTING: any = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/users/grids/c8i9eKoWMBWZgx9o9rIh",
	},
	map: true,
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

// CONTACT
const CONTACT_SETTING: any = setting({
	accid: "default",
	appid: "contact",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/contact/forms/wRProErhOr20gctY9IgO",
	},
});

// BANNER
const BANNER_SETTING: any = setting({
	accid: "default",
	appid: "banner",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "order",
	mapItems: {
		referencePath: "default/banner/grids/tbdlFvLpACmtWj9u1dNh",
	},
});

// SHIPPING
const SHIPPING_SETTING: any = setting({
	accid: "default",
	appid: "shippings",
	colid: "documents",
});

// NFE PDV
const NFE_SETTING: any = setting({
	accid: "default",
	appid: "nfPDV",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/nfPDV/grids/wEL0j9txZ0srvUJFvooX",
	},
	form: {
		referencePath: "default/nfPDV/forms/FVFNKboMArbgYlamDmUH",
	},
});

const NFCE_SETTING: any = setting({
	accid: "default",
	appid: "nfce",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/nfce/grids/z0U7sSysQ0etpi7Hqxue",
	},
	form: {
		referencePath: "default/nfce/forms/9owNRRkkVaCVORDWDdGk",
	},
});

//CHART API SETTINGS
const DAY_CHART_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: "ecom",
	order: "postdate",
	perPage: 9999,
	map: true,
	chart: {
		referencePath: "default/orderPDV/chart/fyCmhIqhRtq7cD12broW",
	},
	where: [
		{
			field: "postdate",
			operator: ">=",
			value: "2022-01-01T00:00:00-03:00",
		},
		{
			field: "postdate",
			operator: "<=",
			value: "2022-12-31T23:59:59-03:00",
		},
	],
});

const WEEK_CHART_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: "ecom",
	order: "postdate",
	perPage: 9999,
	map: true,
	chart: {
		referencePath: "default/orderPDV/chart/FiQS4uCCWh9S78YBiCGe",
	},
	where: [
		{
			field: "postdate",
			operator: ">=",
			value: "2022-01-01T00:00:00-03:00",
		},
		{
			field: "postdate",
			operator: "<=",
			value: "2022-12-31T23:59:59-03:00",
		},
	],
});

const MONTH_CHART_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: "ecom",
	order: "postdate",
	perPage: 9999,
	map: true,
	chart: {
		referencePath: "default/orderPDV/chart/uhoDTPX5GPq8uw4b7Azq",
	},
	where: [
		{
			field: "postdate",
			operator: ">=",
			value: "2022-01-01T00:00:00-03:00",
		},
		{
			field: "postdate",
			operator: "<=",
			value: "2022-12-31T23:59:59-03:00",
		},
	],
});

const STORAGE_SETTING: any = setting({
	maccid: "default",
	accid: "default",
	appid: "apps",
	platform: platform,
	colid: "documents",
	fields: "maccid,accid,appid,colid,platform",
});

const MENU_PDV_SETTING = setting({
	accid: "default",
	appid: "menuPDV",
	colid: "documentsMenuPDV",
	platform: platform,
	orderBy: "order",
	map: true,
	mapItems: {
		referencePath: "default/menuPDV/grids/QEgUJRKKStkbWc8Cn9zz",
	},
});

const REORDER_SETTING: any = setting({
	accid: "default",
	appid: "reorderPDV",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	map: true,
	asc: false,
	ownerField: "client",
	disabledReversalCode: true,
	form: {
		referencePath: "default/reorderPDV/forms/IH6t85IhCeirnCIUoyWO",
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
	mapItems: {
		referencePath: "default/reorderPDV/grids/SZ20QiZzDCXfObqwH93I",
	},
});

const GRID_REORDER_SETTING: any = setting({
	accid: "default",
	appid: "orderPDV",
	colid: "documents",
	platform: platform,
	document: {
		// default/orderPDV/grids/QnO0PhgvdTCsITeVytQC
		referencePath: "default/reorderPDV/grids/mB6aVhDkqbO1o9qXubNO",
	},
	map: true,
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

const FORM_REORDER_SETTING: any = setting({
	accid: "default",
	appid: "reorderPDV",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/reorderPDV/forms/IH6t85IhCeirnCIUoyWO",
	},
	map: true,
	mapItems: {
		referencePath: "default/reorderPDV/forms/IH6t85IhCeirnCIUoyWO",
	},
});

const COLLECTION_GRID_REORDER_SETTING: any = setting({
	accid: "default",
	appid: "reorderPDV",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/reorderPDV/grids/mB6aVhDkqbO1o9qXubNO",
	},
});

const LANGUAGE_SETTING: any = setting({
	accid: "default",
	appid: "language",
	colid: "documents",
	platform: platform,
	orderBy: "pt",
	perPage: 5000,
	form: {
		referencePath: "default/language/forms/RCAJHDjjyT2VAlRkjwWz",
	},
	mapItems: {
		referencePath: "default/language/grids/O6HJAxVndwt8YV1TQEOf",
	},
});

const GRID_LANGUAGE_SETTING: any = setting({
	accid: "default",
	appid: "language",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/language/grids/xBJeaJBFFEnVLGve80XY",
	},
	map: true,
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

const FORM_LANGUAGE_SETTING: any = setting({
	accid: "default",
	appid: "language",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/language/forms/YcSn7Zy5EaaT4IyZj74y",
	},
	map: true,
	mapItems: {
		referencePath: "default/language/forms/YcSn7Zy5EaaT4IyZj74y",
	},
});

const ADMIN_APP_SETTING = setting({
	accid: "default",
	appid: "admin",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/admin/grids/LNBBseRY9WAQVP6enw8Y",
	},
	form: {
		referencePath: "default/admin/forms/h6iZEMbVk1R3I8rFUeYZ",
	},
});

const GRID_ADMIN_APP_SETTING: any = setting({
	accid: "default",
	appid: "admin",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: "default/admin/grids/njQ8b0qmcOr1ZbWo0fqL",
	},
	mapItems: {
		referencePath: "default/accounts/grids/gg1nbs2RtkqLLhEddYXV",
	},
});

const FORM_ADMIN_APP_SETTING = setting({
	accid: "default",
	appid: "admin",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/admin/forms/h6iZEMbVk1R3I8rFUeYZ",
	},
	mapItems: {
		referencePath: "default/admin/forms/h6iZEMbVk1R3I8rFUeYZ",
	},
});

const GROUP_OPTIONS_SETTING = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/option/documents/G2JmC8qj3fB4KUelJoJd",
	},
	map: true,
	mapItems: {
		referencePath: "default/option/grids/l3nQUnHfHzoKTEkNfRh8",
	},
});

const DEPOSIT_SETTING = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "deposit",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/deposit/grids/W8IEDQQxNIhun4cZOcpN",
	},
	form: {
		referencePath: "default/deposit/forms/G9eK5KgLRzc54N0Dro6m",
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
	bonusData: {
		referencePath: "default/levelTables/documents/LvFtv8TCpHHSuJhO1nxV",
	},
	//noCache: true,
});

const UPDATE_FIXTURE_SETTING = setting({
	accid: "default",
	appid: "fixture",
	colid: "documents",
	platform: platform,
	orderBy: "startDate",
	// noCache: true,
	asc: true,
	map: true,
	form: {
		referencePath: "default/fixture/forms/621e8n1IszIMAYtx0DwW",
	},
	mapItems: {
		referencePath: "default/fixture/grids/CZUHuX8e0I6wvYP2HLEe",
	},
});

const FLAT_FIXTURE_SETTING = setting({
	accid: "default",
	// accid: getAccid(),
	appid: "fixture",
	colid: "flatFixture",
	platform: platform,
	orderBy: "startDate,league.id",
	// noCache: true,
	asc: true,
	map: true,
	form: {
		referencePath: "default/fixture/forms/621e8n1IszIMAYtx0DwW",
	},
	mapItems: {
		referencePath: "default/fixture/grids/VYTGuKtC61fAf8a3j1hH",
	},
});

const PARTNER_SETTING = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "partner",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/partner/grids/841jbcDweJccMZF9F3F9",
	},
	form: {
		referencePath: "default/partner/forms/Hos4CpurLJqNBXrEbqwi",
	},
});

const SPORT_SETTING = setting({
	accid: "default",
	// accid: getAccid(),
	appid: "sport",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/sport/grids/NtGI1dbMiPFk1TS0Nugx",
	},
	form: {
		referencePath: "default/sport/forms/he3W7CVQ7yfuax3euveU",
	},
});

const MARKET_SETTING = setting({
	accid: "default",
	appid: "market",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/sport/grids/3YseB9b73JQizxCQqypW",
	},
	form: {
		referencePath: "default/sport/forms/1gAU636GvEooTFQ7u1cy",
	},
});

const LEAGUE_SETTING = setting({
	accid: "default",
	appid: "league",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/league/grids/xqFIjhZyJPJSVfxo5oOG",
	},
	form: {
		referencePath: "default/league/forms/yxi6n4sed0bm64Lydwsn",
	},
});

const LOCATION_SETTING = setting({
	accid: "default",
	appid: "location",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/location/grids/rcsy1MmkDTABUIRX7bff",
	},
	form: {
		referencePath: "default/location/forms/ZfCGY3setGucA7bcAnnd",
	},
});

const MESSAGE_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "message",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/message/grids/MfY31eooAdryuCeO0OPu",
	},
	form: {
		referencePath: "default/message/grids/qVddiTNKw5qa9VWsrWO4",
	},
});

const CASINO_SETTING = setting({
	accid: getAccid(),
	appid: "casino",
	colid: "documents",
	map: true,
	platform: platform,
	maccid: "default",
	mapItems: {
		referencePath: "default/casino/grids/HTV9e0B7YgE6VVvnERHp",
	},
	form: {
		referencePath: "default/casino/forms/vomO7BSqT5AuXdrTOvED",
	},
});

const POST_SETTING = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "posts",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/posts/grids/CWuR4SskuO6WUTvLttvE",
	},
	form: {
		referencePath: "default/posts/forms/6SAWYU3P2vNnlrls2gTT",
	},
});

const EVENT_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "events",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/events/grids/ptsBebZH6otVNWubKt0q",
	},
	form: {
		referencePath: "default/events/forms/DebnIN3v6Z6TaOHtBMR7",
	},
});

const WITHDRAW_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "withdraw",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/withdraw/grids/wGOoNJa6NfeupGtsdshI",
	},
	form: {
		referencePath: "default/withdraw/forms/Jk98rCCDSQLm0uyCmrSz",
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
});

const TRANSFER_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "transfer",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/transfer/grids/Ew6QlEMKiuyHt0feXHHU",
	},
	form: {
		referencePath: "default/transfer/forms/gNFrqW2xBW7hKZ7B2mUT",
	},
	plusBonus: {
		referencePath: `${getAccid()}/depositBonus/documents/2AjMdcd4qyy5EPNErMPh`,
	},
	scheduledBonus: {
		referencePath: `${getAccid()}/depositBonus/documents/faJsT8AnAorMph20kWfu`,
	},
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
});

const ADVICE_SETTING = setting({
	accid: "default",
	appid: "advices",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	orderBy: "postdate",
	asc: false,
	mapItems: {
		referencePath: "default/advices/grids/8LuiZpu8DabBbFoTrxKD",
	},
	form: {
		referencePath: "default/advices/forms/YfcvU8QZfvGqHawu8ibA",
	},
});

const BANK_OPTIONS_SETTING = setting({
	accid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: "default/option/documents/3SmZbWGsvgoEHyeKSGSN",
	},
	map: true,
	mapItems: {
		referencePath: "default/option/grids/eOSSt7GuN91qGN0sV97l",
	},
});

const BET_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	walletForm: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	form: {
		referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
});

const FIXTURE_MENU_SETTING = setting({
	accid: "default",
	appid: "fixtureMenu",
	colid: "documentsMenu",
	platform: platform,
	document: {
		referencePath: "default/fixtureMenu/documentsMenu/default",
	},
	map: true,
	mapItems: {
		referencePath: "default/fixtureMenu/grids/S5zB0A9G9tuXCxVvy1wv",
	},
	form: {
		referencePath: "default/fixtureMenu/forms/E3mt1mLMru5AMDQ1a2Uw",
	},
});

const POPUP_SETTING = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "popup",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	mapItems: {
		referencePath: "default/popup/grids/IymBm2j4ZTGedRobgAqk",
	},
	form: {
		referencePath: "default/popup/forms/kd2iYME13frAavpoJUmi",
	},
});

const POINTS_REWARD_SETTING: any = setting({
	// accid: 'default',
	maccid: "default",
	accid: getAccid(),
	appid: "pointsRewards",
	colid: "documents",
	platform: platform,
	// orderBy: "postdate",
	// asc: false,
	//noCache: true,
	map: true,
	form: {
		referencePath: "default/pointsRewards/forms/TMLUwZcIBLndZzt2uQoa",
	},
	mapItems: {
		referencePath: "default/pointsRewards/grids/mxfAvRddWD84PhzzsPLj",
	},
});

const BET_BONUS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "betBonus",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/betBonus/forms/ufLLixbEU9yOVT5pYUTM",
	},
	mapItems: {
		referencePath: "default/betBonus/grids/5XCk9Kejpy3ArW9hTzG0",
	},
});

// accid: 'default',
const DEPOSIT_BONUS_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "depositBonus",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/depositBonus/forms/PYwCD9HJ6AXCPichmgxp",
	},
	mapItems: {
		referencePath: "default/depositBonus/grids/id7a6CxWfmJTt34syHfV",
	},
	//noCache: true,
	orderBy: "position",
});

const CASINO_TRANSACTIONS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "casinoTransactions",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	form: {
		referencePath: "default/casinoTransactions/forms/BZzWhM80ACVSrdor1OUA",
	},
	mapItems: {
		referencePath: "default/casinoTransactions/grids/wRq6GgetRvdzXgNUaAx2",
	},
});

const PARTICIPANTS_SETTING = setting({
	accid: "default",
	appid: "participant",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	form: {
		referencePath: "default/participant/forms/SFWsI6LOoJQtwQjA7PrM",
	},
	mapItems: {
		referencePath: "default/participant/grids/euCuYw57d8TXaM1vyrMx",
	},
});

const ADVICES_USERS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "advicesUsers",
	colid: "documentsUsers",
	platform: platform,
	map: true,
	//noCache: true,
	orderBy: "postdate",
	asc: false,
	fieldUser: "client",
	form: {
		referencePath: "/default/minigame/documents/TSOfF3osfeyCf6vXRZ1U",
	},
	mapItems: {
		referencePath: "default/advicesUsers/grids/RtLNCautUFYevlNkh7Q6",
	},
});

const MINIGAME_ROOM_SETTING = setting({
	accid: "default",
	appid: "minigame",
	colid: "documents",
	platform: platform,
	// map: true,
	// mapItems: {
	//   referencePath: 'default/minigame/rooms/grids/pXML71B22iZPIuy3EgIL',
	// },
});

const BACCARAT_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: `/${getAccid()}/minigame/documents/pXCNVbMkzkKi6a7p7VLS`,
	},
	map: true,
	mapItems: {
		referencePath: "default/minigame/grids/pXML71B22iZPIuy3EgIL",
	},
});

const ODD_OR_EVEN_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "option",
	colid: "documents",
	platform: platform,
	document: {
		referencePath: `/${getAccid()}/minigame/documents/ULy9uk9MrooEOQRqeDSR`,
	},
	map: true,
	mapItems: {
		referencePath: "default/minigame/grids/yJgrQtSOOZwrPFHuD24U",
	},
});

const DEPOSIT_LEVELS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/1Vzf9BCElKhtUHWxkeHI`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/csDogkhG51Gu2pfpvKYW",
	},
});

const DEPOSIT_LEVELS_BONUS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	//noCache: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/LvFtv8TCpHHSuJhO1nxV`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/UMcAA7dyxVBYzm6vrqBO",
	},
});

const WITHDRAW_LEVELS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/4TizoKn4vTmUqVBpoSyo`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/ZVTATCLfmrybkAz6Y9JK",
	},
});

const SPORT_LEVEL_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/zEE6h1WsHonx2SmBHhIj`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/e8a8OkL5JpXDqgyQAZgB",
	},
});

const LIVE_LEVEL_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/C6xEhbMVjwa9ilRIIPD9`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/sG1CYbXqr1JbqRHSEC8T",
	},
});

const PAYBACK_LEVEL_SETTING = setting({
	// accid: 'default',

	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	levelTable: {
		referencePath: `${getAccid()}/levelTables/documents/config`,
	},
	// document : {
	//     referencePath : `${getAccid()}/levelTables/documents/ALP8rIPRVMuJ6IPbFyBN`,
	// },

	mapItems: {
		referencePath: "default/levelTables/grids/sG1CYbXqr1JbqRHSEC8T",
	},
});

const MGM_LEVEL_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/dIoZh00KhT0ymQFt8nni`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/ZvbDoDfkLleywWomckT3",
	},
});

const TURNOVERS_SETTING = setting({
	accid: "default",
	appid: "turnover",
	colid: "turnoverDocuments",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/turnover/grids/9GeSCKFCCTcSyoeqLaY8",
	},
	form: {
		referencePath: "default/turnover/forms/1kEIilvZGbPGRYQWhbtp",
	},
});

const GENERAL_CONFIGS_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/MXIT0e9dERhKTFHqA6VP`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/dz3udIcXoXhHoyFfT2n5",
	},
});

const DIVIDEND_CONFIG_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/7JkchxNheBXvCZddsUc2`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/IUdQTIO1rpxt5rDYUcOM",
	},
});

const LOTUS_CONFIG_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/jtCVC4r8yGwacMv9tfjW`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/BVaVJ4kxKCwDuG9bniM6",
	},
});

const BOSCORE_CONFIG_SETTING = setting({
	// accid: 'default',
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	document: {
		referencePath: `${getAccid()}/levelTables/documents/cnNCic3offeyU3Pa3KDJ`,
	},
	mapItems: {
		referencePath: "default/levelTables/grids/MegU21icz3pDjs0mrPnb",
	},
});

const SIZE_BANNERS_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/xEq1Zmgf02Nb2slXd40q",
	},
	document: {
		referencePath: `${getAccid()}/page/documents/GbjAO45PIt5yQRcLsNqh`,
	},
});

const MESSAGE_TWO_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "messageTwo",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/messageTwo/forms/6xTXxny1fVZKjJdZYEaT",
	},
	mapItems: {
		referencePath: "default/messageTwo/grids/0CH0MQQKtrxmCskqcrAX",
	},
});

const DEPOSIT_BONUS_CASINO_TABLE_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/levelTables/grids/csDogkhG51Gu2pfpvKYW",
	},
	document: {
		referencePath: `${getAccid()}/levelTables/documents/tPwZNGpL7D9VmSERNwB3`,
	},
});

const FIRST_DEPOSIT_BONUS_CASINO_TABLE_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/levelTables/grids/csDogkhG51Gu2pfpvKYW",
	},
	document: {
		referencePath: `${getAccid()}/levelTables/documents/1Gk7I8IHAL89S4oAIUbB`,
	},
});

const DEPOSIT_BONUS_SPORT_TABLE_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/levelTables/grids/csDogkhG51Gu2pfpvKYW",
	},
	document: {
		referencePath: `${getAccid()}/levelTables/documents/hI4FuBh4VpuqfEhlYi01`,
	},
});

const FIRST_DEPOSIT_BONUS_SPORT_TABLE_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/levelTables/grids/csDogkhG51Gu2pfpvKYW",
	},
	document: {
		referencePath: `${getAccid()}/levelTables/documents/WZSqIUm9MmoHKWAtaXeY`,
	},
});

const PAYBACK_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "payback",
	colid: "documents",
	map: true,
	mapItems: {
		referencePath: "/default/payback/grids/vevtiDttAfWnRZrBFnKJ",
	},
	levelTable: {
		referencePath: `${getAccid()}/levelTables/documents/config`,
	},
	// levelTable : {
	//     referencePath : `${getAccid()}/levelTables/documents/ALP8rIPRVMuJ6IPbFyBN`
	// },

	form: {
		referencePath: "default/payback/forms/zLIfik38FLcu39LocqyE",
	},
	plusBonus: {
		referencePath: `${getAccid()}/depositBonus/documents/2AjMdcd4qyy5EPNErMPh`,
	},
});

const APIS_MAP: any = {
	"stage-goni.metasport777.com": "https://api.stage-goni.metasport777.com",
	"prod-kr.metasport777.com": "https://api.prod-goni.metasport777.com",
	"goni-01.com": "https://api.goni-01.com",
	"goni-02.com": "https://api.goni-02.com",
	"goni-03.com": "https://api.goni-03.com",
	"goni-04.com": "https://api.goni-04.com",
	"goni-05.com": "https://api.goni-05.com",
};

const SUBTRANSLANTE_MAP = {
	Esporte: "보유금액",
	"Dinheiro Esporte": "보유금액",
	Cassino: "카지노머니",
	"Dinheiro do Cassino": "카지노머니",
	"*Os membros que utilizarão principalmente cassinos devem selecionar o bônus do cassino.":
		"*보너스없음100% 매충200%  첫충300% 입플600% 롤링이 적용됩니다.",
};

const CONFIG_SETTING = setting({
	accid: getAccid(),
	maccid: "default",
	appid: "levelTables",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/levelTables/grids/srNsGcIKXjjMOd7EiHAx",
	},
	document: {
		referencePath: `${getAccid()}/levelTables/documents/config`,
	},
});

const TRANSACTIONS_URL = process.env.NEXT_MONOREPO_API_URL;

const MESSAGE_API_URL = process.env.NEXT_PUBLIC_MESSAGE_API_URL;

const MONOREPO_API_URL = process.env.NEXT_MONOREPO_API_URL;

const THEME_SETTING = {
	homeLinks: LinksTabs,
	headerType: process.env.NEXT_PUBLIC_HEADER_TYPE,
	redirectType: "home", //home, loginPage
	// homeOpenModal: true, // true or false

	themeCasino: true,
	// flag temporario que habilita/desabilita email
	hasEmail: true,

	// variaveis pagina de Deposito
	disabledTabsDeposit: true,
	disabledInformationsCashDeposit: true,
	//disabledBonnusDeposit           : true,
	layoutAltered: true,
	disabledTabsHistoricBet: true,
	disabledTabCotentHistoricBet: true,
	disabledTurnoverInfo: true,
	disabledSportCashHistoryTab: true,
	showBanks: true,
	disabledBetSlipe: true,
	useSubTranslate: true,
	disabledTransferHelpers: true,
	//showMinigameNameinEnglish       : true,
	openExternalsInNewTab: true,
	disabledHomeSecondRow: true,
	// leftMenuBanners                  : true,
	alternateLeftMenu: AnimatedMenu,
	alternateProvidersLinksComponent: LinksTabs,
	hideIconOnHometabs: true,
	overrideSponsors: true,
	disabledHomeMobileLinks: true,
	thirdRowComponent: NewsComponent,
	scrollingText: "◆ 평생도메인 안내 : 고니카지노.com ◆",
	scrollingText2:
		"◈ 게임실행 오류시 팝업차단 해제,쿠키삭제 후 실행하시기 바랍니다.◈",
	headerInMiddle: true,
};

export {
	ADMIN_SETTING,
	VIDEO_SETTING,
	PAGE_SETTING,
	PAGE_TEXT_SETTING,
	VARIANT_SETTING,
	BANNER_SETTING,
	FAVORITE_SETTING,
	ACCOUNT_SETTING,
	CATEGORY_SETTING,
	FAQ_SETTING,
	STORE_SETTING,
	CONTAINER_SETTING,
	AUTH_SETTING,
	CART_BET_SETTING,
	PRODUCT_SETTING,
	COMMET_SETTING,
	ORDER_SETTING,
	MINIGAME_SETTING,
	BRAND_SETTING,
	OPTION_SETTING,
	BLOG_SETTING,
	HOME_PAGE_SETTING,
	SHIPPING_SETTING,
	CONTACT_SETTING,
	PAYMENT_METHOD_OPTION_SETTING,
	GRID_AUTH_SETTING,
	GRID_PRODUCT_SETTING,
	COLLECTION_GRID_PRODUCT_SETTING,
	COLLECTION_GRID_AUTH_SETTING,
	GRID_ORDER_SETTING,
	ORDER_ACTIONS_OPTION_SETTING,
	CASHIER_SETTING,
	NFE_SETTING,
	NFCE_SETTING,
	SELLER_SETTING,
	OPTIONS_SETTING,
	CASHIER_OPTIONS_SETTING,
	DAY_CHART_SETTING,
	WEEK_CHART_SETTING,
	MONTH_CHART_SETTING,
	FORM_AUTH_SETTING,
	FORM_PRODUCT_SETTING,
	FORM_ORDER_SETTING,
	STORAGE_SETTING,
	MENU_PDV_SETTING,
	REORDER_SETTING,
	POINTS_REWARD_SETTING,
	FORM_REORDER_SETTING,
	GRID_REORDER_SETTING,
	COLLECTION_GRID_REORDER_SETTING,
	// REDIRECT_LOGIN_PATH,
	platform,
	LANGUAGE_SETTING,
	GRID_LANGUAGE_SETTING,
	FORM_LANGUAGE_SETTING,
	ADMIN_APP_SETTING,
	GRID_ADMIN_APP_SETTING,
	FORM_ADMIN_APP_SETTING,
	GROUP_OPTIONS_SETTING,
	DEPOSIT_SETTING,
	// FLAT_FIXTURE_SETTING,
	PARTNER_SETTING,
	SPORT_SETTING,
	LEAGUE_SETTING,
	LOCATION_SETTING,
	MESSAGE_SETTING,
	CASINO_SETTING,
	POST_SETTING,
	EVENT_SETTING,
	WITHDRAW_SETTING,
	TRANSFER_SETTING,
	ADVICE_SETTING,
	BANK_OPTIONS_SETTING,
	BET_SETTING,
	FIXTURE_MENU_SETTING,
	POPUP_SETTING,
	BET_BONUS_SETTING,
	DEPOSIT_BONUS_SETTING,
	CASINO_TRANSACTIONS_SETTING,
	PARTICIPANTS_SETTING,
	FLAT_FIXTURE_SETTING,
	UPDATE_FIXTURE_SETTING,
	ADVICES_USERS_SETTING,
	BACCARAT_SETTING,
	DEPOSIT_LEVELS_SETTING,
	DEPOSIT_LEVELS_BONUS_SETTING,
	WITHDRAW_LEVELS_SETTING,
	SPORT_LEVEL_SETTING,
	LIVE_LEVEL_SETTING,
	MGM_LEVEL_SETTING,
	DEPOSIT_BONUS_CASINO_TABLE_SETTING,
	FIRST_DEPOSIT_BONUS_CASINO_TABLE_SETTING,
	DEPOSIT_BONUS_SPORT_TABLE_SETTING,
	FIRST_DEPOSIT_BONUS_SPORT_TABLE_SETTING,
	TURNOVERS_SETTING,
	ODD_OR_EVEN_SETTING,
	MARKET_SETTING,
	GENERAL_CONFIGS_SETTING,
	MINIGAME_ROOM_SETTING,
	DIVIDEND_CONFIG_SETTING,
	PROVIDER_SETTING,
	LOTUS_CONFIG_SETTING,
	BOSCORE_CONFIG_SETTING,
	REVALIDATE_SETTING,
	THEME_SETTING,
	SIZE_BANNERS_SETTING,
	SUBTRANSLANTE_MAP,
	MESSAGE_TWO_SETTING,
	APIS_MAP,
	PAYBACK_SETTING,
	PAYBACK_LEVEL_SETTING,
	TRANSACTIONS_URL,
	CONFIG_SETTING,
	MESSAGE_API_URL,
	MONOREPO_API_URL,
};
