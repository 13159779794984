import { THEME_SETTING } from "@/setting/setting";
import {
	Box,
	Button,
	TabList,
	TabPanel,
	TabPanels,
	useTab,
} from "@chakra-ui/react";
import { Tabs } from "@chakra-ui/react";
import React from "react";
import { CardLive } from "../../bet-nextv1/CasinoComponents/CardLive";
import { CardSlotProvider } from "../../bet-nextv1/CasinoComponents/CardSlotProvider";
import styles from "./styles.module.scss";

export const LinksTabs = ({ casino, providers, deactivatedCasino }: any) => {
	const CustomTab = React.forwardRef((props, ref) => {
		const tabProps = useTab({ ...props, ref });
		const isSelected = !!tabProps["aria-selected"];

		return (
			<Button
				className={
					isSelected ? `${styles.tabLinks} ${styles.selected}` : styles.tabLinks
				}
				{...tabProps}
			>
				<Box as="span" mr="2" />
				{tabProps.children}
			</Button>
		);
	});

	return (
		<>
			<Tabs>
				<TabList>
					{!THEME_SETTING.focusSlot && (
						<>
							<CustomTab>
								카지노 <span>CASINO</span>{" "}
								{!THEME_SETTING.hideIconOnHometabs ? (
									<img src={"/assets/img/casinocash.png"} />
								) : (
									<div className={styles.filler} />
								)}
							</CustomTab>
							<CustomTab>
								슬롯게임 <span>SLOT GAME</span>{" "}
								{!THEME_SETTING.hideIconOnHometabs ? (
									<img src={"/assets/img/slot.png"} />
								) : (
									<div className={styles.filler} />
								)}
							</CustomTab>
						</>
					)}

					{THEME_SETTING.focusSlot && (
						<>
							<CustomTab>
								슬롯게임 <span>SLOT GAME</span>{" "}
								{!THEME_SETTING.hideIconOnHometabs ? (
									<img src={"/assets/img/slot.png"} />
								) : (
									<div className={styles.filler} />
								)}
							</CustomTab>
							<CustomTab>
								카지노 <span>CASINO</span>{" "}
								{!THEME_SETTING.hideIconOnHometabs ? (
									<img src={"/assets/img/casinocash.png"} />
								) : (
									<div className={styles.filler} />
								)}
							</CustomTab>
						</>
					)}
				</TabList>

				{!THEME_SETTING.focusSlot && (
					<TabPanels>
						<TabPanel>
							{casino?.map(
								(lobby: any) =>
									lobby?.status && (
										<div
											className={
												lobby?.maintenance || deactivatedCasino
													? styles.noAnimation
													: ""
											}
											key={lobby.id}
										>
											<CardLive
												game={lobby}
												maintenance={lobby?.maintenance || deactivatedCasino}
											/>
										</div>
									),
							)}
						</TabPanel>
						<TabPanel>
							{providers?.map(
								(provider: any) =>
									provider?.status && (
										<div
											key={provider.id}
											className={deactivatedCasino ? styles.noAnimation : ""}
										>
											<CardSlotProvider
												provider={provider}
												maintenance={deactivatedCasino}
											/>
										</div>
									),
							)}
						</TabPanel>
					</TabPanels>
				)}
				{THEME_SETTING.focusSlot && (
					<TabPanels>
						<TabPanel>
							{providers?.map(
								(provider: any) =>
									provider?.status && (
										<div
											key={provider.id}
											className={deactivatedCasino ? styles.noAnimation : ""}
										>
											<CardSlotProvider
												provider={provider}
												maintenance={deactivatedCasino}
											/>
										</div>
									),
							)}
						</TabPanel>
						<TabPanel>
							{casino?.map(
								(lobby: any) =>
									lobby?.status && (
										<div
											className={
												lobby?.maintenance || deactivatedCasino
													? styles.noAnimation
													: ""
											}
											key={lobby.id}
										>
											<CardLive
												game={lobby}
												maintenance={lobby?.maintenance || deactivatedCasino}
											/>
										</div>
									),
							)}
						</TabPanel>
					</TabPanels>
				)}
			</Tabs>
		</>
	);
};
