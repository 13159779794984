import { APIS_MAP, THEME_SETTING } from "@/setting/setting";

const LOGIN_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/login/`;

export default async function loginAuthBet(setting: ISetting) {
	const result = await call(LOGIN_BET_SERVER, setting);

	return result;
}

interface ISetting {
	merge: any;
	status: boolean;
	accid: string;
	appid: string;
	colid: string;
	toJson: () => any;
}

const _setting = (data: any): ISetting => {
	const obj = Object.assign(
		{
			merge: function (data: any) {
				return _setting(Object.assign({}, this, data));
			},
		},
		data,
	);

	Object.defineProperty(obj, "merge", {
		writable: true,
		enumerable: false,
		configurable: true,
	});

	Object.defineProperty(obj, "toJson", {
		value: function () {
			return Object.entries(this).reduce(
				(result, [key, value]) => {
					if (typeof value !== "function") {
						result[key] = value;
					}

					return result;
				},
				{} as Record<string, unknown>,
			);
		},
		writable: true,
		enumerable: false,
		configurable: true,
	});

	return obj;
};

const call = async (url: string, body?: any) => {
	if (typeof window !== "undefined" && APIS_MAP) {
		const currentHostname = window.location.hostname;
		const apiUrl = APIS_MAP[currentHostname];

		if (apiUrl) {
			url = url.replace(process.env.NEXT_PUBLIC_API_SOURCE_URL || "", apiUrl);
		}
	}

	const headers: any = {
		Accept: "application/json",
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": "true",
	};

	const response = await fetch(url, {
		method: "POST",
		body: JSON.stringify(body),
		headers: headers,
		credentials: "include",
		cache: body.cache ? body.cache : "default",
		next: { revalidate: THEME_SETTING.revalidate },
	});

	if (response.status >= 400) {
		console.error("http-error", url, body);
	}

	const result = await response.json();

	return result;
};
